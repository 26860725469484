<template>
  <b-card class="item-header mb-3" :class="{ 'no-border': noBorder }">
    <section class="header-title">
      <h4>{{ title }}</h4>
      <div class="ml-auto">
        <slot name="header" />
        <ChevronDown
          v-if="collapseButton === 'chevron'"
          class="icon"
          :class="{ 'is-open': isOpen }"
          :v-b-toggle="`slot-content-${id}`"
          @click="isOpen = !isOpen"
        />

        <b-button
          v-if="collapseButton === 'button'"
          variant="link"
          size="sm"
          :v-b-toggle="`slot-content-${id}`"
          @click="isOpen = !isOpen"
        >
          {{ isOpen ? 'Ver menos' : 'Ver mais' }}
        </b-button>
      </div>
    </section>
    <b-collapse :id="`slot-content-${id}`" v-model="isOpen" class="mt-4">
      <hr />
      <slot name="content" />
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'ItemHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    noBorder: Boolean,
    collapseButton: {
      type: String,
      default: 'chevron',
      validator: value => ['chevron', 'button'].includes(value)
    }
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  data: () => ({
    isOpen: true
  }),
  created() {
    if (this.collapseButton === 'button') {
      this.isOpen = false
    }
  },
  computed: {
    id() {
      return `slot-content-${this.title
        .replace(/\s/g, '-')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')}`
    }
  }
}
</script>

<style lang="scss" scoped>
.item-header {
  &.no-border {
    border: none !important;
  }
  border-radius: 8px !important;
  .header-title {
    display: flex;
    align-items: center;
    h4 {
      color: var(--dark-blue);
      font-size: min(2.6vw, 18px);
      font-weight: 600;
    }
    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      stroke: var(--neutral-600);

      &.is-open {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>
